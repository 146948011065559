(function ($) {
  $(function () {

    /*
     * About spy
     */
    // $('body').scrollspy({ target: '#sidebar-active' })

    //     var offset = -180;

    // $('#sidebar-active a').click(function(event) {
    //     event.preventDefault();
    //     $($(this).attr('href'))[0].scrollIntoView();
    //     scrollBy(0, -offset);
    // }); 

    $('#primary-menu-mobie .menu-item-has-children').append('<i class="icon-angle-down"></i>');

    $('#primary-menu-mobie .menu-item-has-children').on('click', function() {
      console.log($(this)); 
      $(this).find('.sub-menu').toggleClass('active');
    });


    /* 
     * Blocks scroll smoth
     */
    $(document).on('click', '.btn-down, .table-of-contents-wrapper a, .about-sidebar-content a[href^="#"]', function (event) {
      event.preventDefault();

      $('html, body').animate({
        scrollTop: $($.attr(this, 'href')).offset().top - 130
      }, 500);
    });


    /* 
     * Blocks scroll smoth
     */
    function isOnScreen(elem) {
      // if the element doesn't exist, abort
      if (elem.length == 0) {
        return;
      }
      var $window = jQuery(window)
      var viewport_top = $window.scrollTop()
      var viewport_height = $window.height()
      var viewport_bottom = viewport_top + viewport_height
      var $elem = jQuery(elem)
      var top = $elem.offset().top
      var height = $elem.height()
      var bottom = top + height

      return (top >= viewport_top && top < viewport_bottom) ||
        (bottom > viewport_top) && (top < viewport_bottom) ||
        (height > viewport_height && top <= viewport_top && bottom >= viewport_bottom)
    }


    window.addEventListener('scroll', function (e) {
      var el = $('.stuck');
      var elnew = $('.pre-stuck');
      var viewport_top = $(window).scrollTop();
      if (elnew.length) {
        var el_top = $('.pre-stuck').offset().top
        var el_top_new = elnew.offset().top
      }


      var containerWidth = $('.container-fluid').outerWidth();
      var sectionWidth = $('#single-post section').outerWidth();
      var tableWraperWidth = (sectionWidth - containerWidth) / 2;
      var windowWidth = $(window).width();
      var rightSpace = (windowWidth - sectionWidth) / 2;

      if (isOnScreen(elnew) || viewport_top < el_top || viewport_top < el_top_new) {
        $('.pre-stuck').removeClass('active');
        $('.table-of-contents-parent').removeClass('active');

        $('.table-of-contents-parent').css({
          'width': '100%',
          'max-width': '100%',
          'right': 'auto',
        })
      } else {
        $('.table-of-contents-parent').addClass('active');
        $('.table-of-contents-parent').removeClass('stuck');


        $('.table-of-contents-parent').css({
          'width': tableWraperWidth,
          'max-width': tableWraperWidth,
          'right': rightSpace,
        })
      }
    });

    // Click on title of table contents
    $('.title-table .hide').on('click', function () {
      $('.table-of-contents-wrapper').toggleClass('show');

      if ($('.table-of-contents-wrapper').hasClass('show')) {
        $(this).text('Zatvori')
      } else {
        $(this).text('Otvori');
      }

    });

    // Click on icon show/hide
    $('.table-of-contents-wrapper i').on('click', function () {
      console.log($(this));
      $(this).next('.sublinks').toggleClass('active');
    });

    // Table of content mobile fix
    if (window.outerWidth < 425) {
      $('.table-of-contents-wrapper').removeClass('show');
      $('.title-table .hide').text('Otvori');

      $('.table-of-contents-wrapper a').on('click', function () {
        $('.table-of-contents-wrapper').removeClass('show');
        $('.title-table .hide').text('Otvori');
      });
    }

    /*
     * Scroll up button
     */
    $(window).scroll(function () {
      if ($(this).scrollTop() > 150) {
        $('.scroll-to-top').addClass('show');
      } else {
        $('.scroll-to-top').removeClass('show');
        $('.scroll-to-top').addClass('animated bounceOutDown');
        $('.scroll-to-top').removeClass('animated bounceOutUp');
        $('.scroll-to-top i').css('transform', 'rotateY(0) scale(1)');
      }
    });
    $('.scroll-to-top').on("click", function () {
      $('.scroll-to-top').addClass('animated bounceOutUp');
      $('.scroll-to-top i').css('transform', 'rotateY(180deg) scale(1.2)');
      $("html, body").animate({
        scrollTop: 0
      }, 1000);
      return false;
    });

    /*
     * Navbar fixed
     */
    $(window).on('scroll', function () {
      if ($(this).scrollTop() > 1) {
        $('.site-header').addClass('navbar-fixed-top');
      } else {
        $('.site-header').removeClass('navbar-fixed-top');
      }
    });

    /*
     * Counter number
     */
    $('.counter').counterUp({
      delay: 10,
      time: 2000
    });

    /*
     * Toggle burger menu on mobile
     */
    $('.mobile-menu-item, .mobile-menu-back').on('click', function (e) {
      e.preventDefault();
      $('body').toggleClass('fixedPosition');
      $(".mobile-menu").toggleClass('sliding-open');
    });

    /*
     * Navigation fixed bottom
     */
    //  $(window).on('scroll', function () {
    //   if ($(this).scrollTop() > 1) {
    //     $('.navigation-bottom').addClass('active animated fadeInUp');
    //   } else {
    //     $('.navigation-bottom').removeClass('active animated fadeInUp');
    //   }
    // });

    /*
     * Owl carousel
     */
    $('.owl-testimonials').owlCarousel({
      margin: 0,
      items: 1,
      loop: true,
      autoplay: true,
      autoplayTimeout: 4500,
      nav: false,
      dots: true,
    });

    /*
     * Video modal 
     */
    $("body").on("click", ".btn-play", function (e) {
      e.preventDefault();
      $("#video-modal").modal("show");
      $('#video-modal').addClass('animated fadeInUp');
      $("#video-modal iframe")[0].src += "?autoplay=1";
    });

    $("#video-modal").on('hidden.bs.modal', function (e) {

      $('#video-modal').removeClass('animated fadeInUp');
      $('#video-modal').addClass('animated fadeInDown');
      var videoUrl = $("#video-modal iframe")[0].src;
      videoUrl = videoUrl.replace("?autoplay=1", "");
      $("#video-modal iframe")[0].src = videoUrl;
    });


    /*
     * Open modal
     */

    $('#open-modal').on('click', function (e) {
      e.preventDefault();
      $('#modal').modal('show');
    });

    /*
     * Youtube lazy loading
     */
    // $('#play-video-btn').on('click', function (e) {
    //   e.preventDefault();
    //   $('#youtubeModal').modal('show');
    // });

    // $("#youtubeModal").on('hidden.bs.modal', function (e) {
    //   $("#youtubeModal iframe").attr("src", $("#youtubeModal iframe").attr("src"));
    // });


    var player;
    var videoModules;
    $('.btn-play').on('click', function () {
      $('#video-modal').modal('show');

      var tag = document.createElement('script');
      tag.src = "https://www.youtube.com/iframe_api";
      document.body.appendChild(tag);
      $(this.parentNode).addClass("playing");
    });

    window.onYouTubeIframeAPIReady = function () {
      videoModules = document.querySelectorAll('.video');
      // for Internet Explorer 11 and below, convert array-like NodeList to an actual Array.
      videoModules = Array.prototype.slice.call(videoModules);
      videoModules.forEach(initializeVideoModule);

    }

    function initializeVideoModule(videoModule) {
      player = new YT.Player(videoModule.querySelector('.video-placeholder'), {
        videoId: videoModule.dataset.videoId,
        events: {
          'onReady': onPlayerReady,
          onStateChange: function (event) {
            if (event.data === 0) {
              // player.destroy();
              $('#video-modal').modal('hide');
              videoModule.querySelector('.video-layer').innerHTML = (
                '<div class="video-placeholder"></div>'
              );
              initializeVideoModuleNew(videoModule);
            }
          },
        }
      });

    }

    function initializeVideoModuleNew(videoModule) {
      player = new YT.Player(videoModule.querySelector('.video-placeholder'), {
        videoId: videoModule.dataset.videoId,
        events: {
          onStateChange: function (event) {
            var isEnded = event.data === YT.PlayerState.ENDED;
            // 'playing' css class controls fading the video and preivew images in/out.
            // Internet Explorer 11 and below do not support a second argument to `toggle`
            // videoModule.classList.toggle('playing', !isEnded);
            videoModule.classList[isEnded ? 'remove' : 'add']('playing');
            // if the video is done playing, remove it and re-initialize
            if (isEnded) {
              player.destroy();
              $('#video-modal').modal('hide');
              videoModule.querySelector('.video-layer').innerHTML = (
                '<div class="video-placeholder"></div>'
              );
              initializeVideoModuleNew(videoModule);
            }
          }
        }
      });
    }

    $('#video-modal').on('hidden.bs.modal', function (event) {
      console.log('close');
      player.pauseVideo();
    })

    function onPlayerReady(event) {
      event.target.mute();
      event.target.playVideo();
    }




    /*
     * Lightbox gallery
     */
    $('.gallery-images').lightGallery({
      selector: '.gallery-item',
      share: true,
      download: true,
      rotate: false,
      showThumbByDefault: true,
      loop: false,
      slideEndAnimatoin: false,
      thumbnail: true,
      allowMediaOverlap: true,
      toggleThumb: true,
      youtubePlayerParams: {
        loadYoutubeThumbnail: true,
        youtubeThumbSize: 'default',
        modestbranding: 1,
        showinfo: 0,
        rel: 0
      }
    });


    $('.post-gallery-images').lightGallery({
      selector: '.post-gallery-item',
      share: true,
      download: true,
      rotate: false,
      showThumbByDefault: true,
      loop: false,
      slideEndAnimatoin: false,
      thumbnail: true,
      allowMediaOverlap: true,
      toggleThumb: true,
      youtubePlayerParams: {
        loadYoutubeThumbnail: true,
        youtubeThumbSize: 'default',
        modestbranding: 1,
        showinfo: 0,
        rel: 0
      }
    });

  });
})(jQuery);